import { graphql } from "gatsby"
import React from "react"
import { Collection } from "../../components/Collection"
import Layout from "../../layouts/MainLayout"

export default function Activities({ data }) {
  let OPEN= [],
    All = [],
    CLOSED = []
  data.allFile.nodes.forEach((element, index) => {
    if (
      new Date(element.childMarkdownRemark.frontmatter.date).toISOString() <
      new Date().toISOString()
    ) {
      CLOSED.push(data.allFile.nodes[index]);
    }else{
      OPEN.push(data.allFile.nodes[index]);
    }
    All.push(data.allFile.nodes[index])
  })

  return (
    <Layout>
      <div className="banner">Events Registration</div>
      <div className="container">
        <nav className="my-4">
          <div
            className="nav nav-pills justify-content-center" //justify-content-md-start
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="nav-all-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-all"
              type="button"
              role="tab"
              aria-controls="nav-all"
              aria-selected="true"
            >
              All
            </button>
            <button
              className="nav-link"
              id="nav-open-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-open"
              type="button"
              role="tab"
              aria-controls="nav-open"
              aria-selected="false"
            >
              Open
            </button>
            <button
              className="nav-link"
              id="nav-closed-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-closed"
              type="button"
              role="tab"
              aria-controls="nav-closed"
              aria-selected="false"
            >
              Closed
            </button>
          </div>
        </nav>
        <div className="tab-content my-3" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-all"
            role="tabpanel"
            aria-labelledby="nav-all-tab"
          >
            <Collection data={All}/>
          </div>
          <div
            className="tab-pane fade"
            id="nav-open"
            role="tabpanel"
            aria-labelledby="nav-open-tab"
          >
            <Collection data={OPEN} noDataTxt={"No Registration Open 😊"}/>
          </div>
          <div
            className="tab-pane fade"
            id="nav-closed"
            role="tabpanel"
            aria-labelledby="nav-pes-closed"
          >
            <Collection data={CLOSED} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query allActivities {
    allFile(
      filter: {
        relativePath: { glob: "data/events/**/**/*" }
        extension: { eq: "md" }
        childMarkdownRemark: {frontmatter: {type: {ne: "SUCCESS"}}}
      }
      sort: { fields: childrenMarkdownRemark___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            by
            date
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            slug
            title
            type
          }
        }
      }
    }
  }
`
